import React, { useState } from "react"

const ArrowBlack = ({ direction, clickEvent, ariaLabel }) => {
  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  const arrowStyles = {
    display: `block`,
    width: `35px`,
    height: `35px`,
    padding: `5px`,
    justifyContent: `center`,
    alignItems: `center`,
    backgroundColor: isHovered ? `#9d5918` : `black`,
    cursor: `pointer`,
    transition: `all 0.2s ease-in-out`,
    transform:
      direction === "left"
        ? `rotate(180deg)`
        : direction === "right"
        ? `rotate(0deg)`
        : `rotate(0deg)`,
  }

  return (
    <>
      <button
        style={arrowStyles}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={clickEvent ? clickEvent : null}
        tabIndex="0"
        aria-label={ariaLabel ? ariaLabel : null}
      >
        <img
          src={
            require("../../../../../assets/icons/ui/right-arrow-white.svg")
              .default
          }
          alt=""
        />
      </button>
    </>
  )
}

export default ArrowBlack
