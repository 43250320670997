import React, { useRef } from "react"
import { Link } from "gatsby"
import FamilyOfBrandsImages from "../../../assets/images/index/family-of-brands"
import * as familyOfBrandsStyles from "./FamilyOfBrands.module.scss"
import "./FamilyOfBrandsLogos.scss"

const FamilyOfBrands = () => {
  const cardRef = useRef(null)

  return (
    <section
      className={familyOfBrandsStyles.wrapper}
      aria-label="Our Family of Brands"
    >
      {/* the 'inner-container' class just sets global x-axis padding on content */}
      <div className="inner-container">
        <h2 className="h1">OUR FAMILY OF BRANDS</h2>
        <ul className={familyOfBrandsStyles.brandsGrid}>
          {/* looping through the imported images array and mapping it out to the grid, note Link component is used for internal NavLinks, but just use traditional anchor tags for external links and buttons */}
          {FamilyOfBrandsImages.map(image => {
            return (
              <li>
                <Link
                  to={image.url}
                  key={image.id}
                  title={image.name}
                  className="brandsLink"
                  aria-label={image.name}
                  ref={cardRef}
                >
                  <div className="brandImage">
                    <picture>
                      <source srcSet={image.MB} media="(max-width:1225px)" />
                      <img src={image.DT} alt="" />
                    </picture>
                  </div>
                  <div className={image.cssLogoClass}>
                    <img src={image.logo} alt="" />
                  </div>
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
    </section>
  )
}

export default FamilyOfBrands
