import React from "react"
import { Link } from "gatsby"
import * as aboutUsStyles from "./AboutUs.module.scss"

const AboutUs = () => {
  return (
    <div className={`inner-container ${aboutUsStyles.wrapper}`}>
      <div className={aboutUsStyles.content}>
        <h2
          className="h3"
          style={{ textTransform: `initial`, fontWeight: `bold` }}
        >
          We are our customer
        </h2>
        <p>
          We proudly live our heritage as a hard-working, multi-generational
          company that makes the tough, comfortable products that our customers
          need at an incredible value.
        </p>
        <Link to="/about-us/" className="CTAblack" title="About Us">
          About Us
        </Link>
      </div>
    </div>
  )
}

export default AboutUs
