import React from "react"
import { Link } from "gatsby"
import * as careersHomeStyles from "./CareersHome.module.scss"

const CareersHome = () => {
  return (
    <div className={careersHomeStyles.wrapper}>
      <div className={careersHomeStyles.innerWrapper}>
        <div className={`inner-container ${careersHomeStyles.content}`}>
          <h2>Are you interested in a career with Rocky Brands?</h2>
          <p>
            Our comprehensive job portal has a wide range of opportunities
            available in almost every field. Find your perfect career fit in a
            variety of locations all over the world.
          </p>
          <div className={careersHomeStyles.ctasWrapper}>
            <a
              href="https://recruiting.ultipro.com/ROC1011RBD/JobBoard/7e2cec98-83e5-418d-9e0e-1a03210a5c51/?q=&o=postedDateDesc&w=&wc=&we=&wpst="
              className="CTAblack"
              title="View Careers"
              target="__blank"
              rel="noopener noreferrer"
            >
              View Careers
            </a>
            <a
              href="https://signin.ultipro.com/ui/#/signin/pass/t/roc1011rbd?goto=https:%2F%2Fsignin.ultipro.com%2Fsignin%2Foauth2%2Ft%2Froc1011rbd%2Fauthorize%3Fclient_id%3Drec-prod-atl-77%26response_type%3Dcode%26scope%3Dopenid%2520profile%26redirect_uri%3Dhttps%253A%252F%252Frecruiting.ultipro.com%252FAuthCode%252FPostLogin%26state%3DreturnUrl%253D%252FROC1011RBD%252FJobBoard%252F7e2cec98-83e5-418d-9e0e-1a03210a5c51%252FHome%252FLoggedIn%253FshowRegisterLink%253DTrue%2526cancelUrl%253Dhttps%25253A%25252F%25252Frecruiting.ultipro.com%25252FROC1011RBD%25252FJobBoard%25252F7e2cec98-83e5-418d-9e0e-1a03210a5c51%25252F%25253Fq%25253D%252526o%25253DpostedDateDesc%257Cta%253DROC1011RBD%26nonce%3DL2V-xWP09wnkRqZ0sqebGv98EzTlR2amlSa2agmuWVQ%26register_uri%3Dhttps%253A%252F%252Frecruiting.ultipro.com%252FROC1011RBD%252FJobBoard%252F7e2cec98-83e5-418d-9e0e-1a03210a5c51%252FAccount%252FRegister%253FcancelUrl%253Dhttps%25253A%25252F%25252Frecruiting.ultipro.com%25252FROC1011RBD%25252FJobBoard%25252F7e2cec98-83e5-418d-9e0e-1a03210a5c51%25252F%25253Fq%25253D%252526o%25253DpostedDateDesc%26locale%3Den-US&realm=%2Ft%2Froc1011rbd&locale=en-US"
              className="CTAalt"
              title="Career Sign-In"
            >
              Sign In
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CareersHome
