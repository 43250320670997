import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HomeHero from "../components/index/hero/HomeHero"
import AboutUs from "../components/index/about-us/AboutUs"
import FamilyOfBrands from "../components/index/family-of-brands/FamilyOfBrands"
import RcifHome from "../components/index/rcif/RcifHome"
import CareersAndContact from "../components/index/careers-and-contact/CareersAndContact"
import StockTicker from "../components/index/stock-ticker/StockTicker"

const IndexPage = ({ pageContext, location }) => (
  <Layout>
    <Seo title="Home" />
    <HomeHero />
    <AboutUs />
    <FamilyOfBrands />
    <RcifHome />
    {/* <StockTicker /> */}
    <CareersAndContact />
  </Layout>
)

export default IndexPage

//head element
export const Head = () => (
  <>
    <title>Home</title>
    <meta
      name="description"
      content="We proudly live our heritage as a hard-working, multi-generational company that makes the tough, comfortable products that our customers need at an incredible value."
    />
  </>
)
