import React, { useState, useRef, useEffect } from "react"
import * as homeCarouselStyles from "./HomeCarousel.module.scss"
import * as homeHeroStyles from "./HomeHero.module.scss"
import ArrowBlack from "../../global/buttons/arrow/arrow-black/ArrowBlack"
import PlayPauseButton from "../../global/buttons/controls/play-pause/PlayPauseBtn"

const HomeCarouselWrapper = ({ children }) => {
  const contentRef = useRef(null)
  const [currentSlide, setCurrentSlide] = useState(0)
  const [totalSlides, setTotalSlides] = useState(children.length)
  const carouselRef = useRef(null)
  const [autoplay, setAutoplay] = useState(true)
  const [touchStart, setTouchStart] = useState(0)
  const [touchEnd, setTouchEnd] = useState(0)
  const [isPlaying, setIsPlaying] = useState(true)

  const prefersReducedMotion = () => {
    if (typeof window !== "undefined") {
      return window.matchMedia("(prefers-reduced-motion: reduce)").matches
    }
    return false
  }

  useEffect(() => {
    if (prefersReducedMotion()) {
      setAutoplay(false)
    }
  }, [])

  const autoplayTimeoutRef = useRef(null)

  useEffect(() => {
    if (autoplay) {
      autoplayTimeoutRef.current = setTimeout(() => {
        nextSlide()
      }, 5000)
    }
    return () => {
      clearTimeout(autoplayTimeoutRef.current)
    }
  }, [autoplay, currentSlide])

  const toggleAutoplay = () => {
    setAutoplay(!autoplay)
    setIsPlaying(!isPlaying)
    console.log(isPlaying)
  }

  useEffect(() => {
    setTotalSlides(children.length)
  }, [children])

  const slideTo = index => {
    setCurrentSlide(index)
    const carousel = carouselRef.current
    carousel.style.transform = `translateX(-${index * 100}%)`
  }

  const prevSlide = () => {
    if (currentSlide === 0) {
      slideTo(totalSlides - 1)
    } else {
      slideTo(currentSlide - 1)
    }
  }

  const nextSlide = () => {
    if (currentSlide === totalSlides - 1) {
      slideTo(0)
    } else {
      slideTo(currentSlide + 1)
    }
  }

  const handleTouchStart = e => {
    setTouchStart(e.targetTouches[0].clientX)
  }

  const handleTouchMove = e => {
    setTouchEnd(e.targetTouches[0].clientX)
  }

  const handleTouchEnd = () => {
    if (touchStart - touchEnd > 45) {
      // Right swipe
      nextSlide()
    }

    if (touchStart - touchEnd < -45) {
      // Left swipe
      prevSlide()
    }

    setTouchStart(0)
    setTouchEnd(0)
  }

  const subheads = [
    "A proud family of leading footwear and apparel brands.",
    "A legacy of producing quality footwear since 1932.",
    "Committed to our communities, partners, associates and customers.",
  ]
  return (
    <section
      style={{ position: `relative` }}
      aria-label="Carousel"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <p class="sr-only">
        This is a carousel with auto-rotating slides. Disable rotation by using
        the pause button. Use Next and Previous buttons to navigate.
      </p>
      <ul className={homeCarouselStyles.carousel} ref={carouselRef}>
        {React.Children.map(children, (child, index) => (
          <li className={homeCarouselStyles.carouselSlide} key={index}>
            {child}
          </li>
        ))}
      </ul>
      {/* content */}
      <div
        className={`inner-container ${homeHeroStyles.content}`}
        ref={contentRef}
      >
        <h1>Welcome to Rocky Brands</h1>
        <div>
          {subheads.map((subhead, index) => {
            if (index === currentSlide) {
              return (
                <p
                  className={homeHeroStyles.active}
                  role="group"
                  aria-label={`Slide ${index + 1} of 4`}
                >
                  {subhead}
                </p>
              )
            } else {
              return (
                <p
                  className={homeHeroStyles.inactive}
                  role="group"
                  aria-label={`Slide ${index + 1} of 4`}
                >
                  {subhead}
                </p>
              )
            }
          })}
        </div>
        <ul className={homeHeroStyles.arrowsWrapper}>
          <li className={homeHeroStyles.button}>
            <ArrowBlack
              direction="left"
              clickEvent={prevSlide}
              ariaLabel="Previous Slide"
            />
          </li>
          <li>
            <PlayPauseButton
              clickEvent={toggleAutoplay}
              isPlaying={isPlaying}
              ariaLabel="Pause/Play Carousel"
            />
          </li>
          <li className={homeHeroStyles.button}>
            <ArrowBlack
              direction="right"
              clickEvent={nextSlide}
              ariaLabel="Next Slide"
            />
          </li>
        </ul>
      </div>
    </section>
  )
}

export default HomeCarouselWrapper
