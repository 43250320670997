import React, { useState, useRef, useEffect } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as rcifHomeStyles from "./RcifHome.module.scss"

const RcifHome = () => {
  const [isPlaying, setIsPlaying] = useState(true)
  const firstImageRef = useRef(null)
  const [buttonLeft, setButtonLeft] = useState(null)

  useEffect(() => {
    const updateButtonPosition = () => {
      const rect = firstImageRef.current.getBoundingClientRect()
      setButtonLeft(rect.left + rect.width / 2)
    }

    window.addEventListener("resize", updateButtonPosition)
    updateButtonPosition()

    return () => {
      window.removeEventListener("resize", updateButtonPosition)
    }
  }, [])

  const handleAnimationToggle = () => {
    setIsPlaying(!isPlaying)
  }

  const playState = isPlaying ? "running" : "paused"

  return (
    <div className={rcifHomeStyles.outerWrapper}>
      {/* renders on mobile, the purpose of having two is due to logical focus order differences */}
      <button
        onClick={handleAnimationToggle}
        className={`${rcifHomeStyles.sliderAnimationToggle} ${rcifHomeStyles.sliderAnimationToggleMobile}`}
        style={{ left: buttonLeft }}
      >
        {isPlaying ? "Pause Slideshow" : "Play Slideshow"}
      </button>
      <div className={rcifHomeStyles.wrapper}>
        {/* background */}
        <div aria-hidden="true" className={rcifHomeStyles.connectedGraphic} />
        {/* main grid */}
        <div className={`inner-container ${rcifHomeStyles.rcifHomeGrid}`}>
          {/* SLIDER */}

          <div className={rcifHomeStyles.slider}>
            <div className={rcifHomeStyles.imagesAndCircleWrapper}>
              {/* circle and shadow */}
              <div className={rcifHomeStyles.circle} aria-hidden="true"></div>

              <div aria-hidden="true" className={rcifHomeStyles.whiteCircle}>
                <StaticImage
                  src="../../../assets/images/index/rcif/white-circle.png"
                  alt=""
                />
              </div>
              {/* slides */}
              <div
                ref={firstImageRef}
                style={{ animationPlayState: playState }}
                className={`${rcifHomeStyles.slideImageWrapper} ${rcifHomeStyles.slide2}`}
              >
                <StaticImage
                  src="../../../assets/images/index/rcif/thumbs-up-no-border.png"
                  alt="Two smiling children giving a joyful thumbs up"
                />
              </div>
              <div
                style={{ animationPlayState: playState }}
                className={`${rcifHomeStyles.slideImageWrapper} ${rcifHomeStyles.slide3}`}
              >
                <StaticImage
                  src="../../../assets/images/index/rcif/rcif-guitar.png"
                  alt="Young boy learning the guitar"
                />
              </div>
              <div
                style={{ animationPlayState: playState }}
                className={`${rcifHomeStyles.slideImageWrapper} ${rcifHomeStyles.slide1}`}
              >
                <StaticImage
                  src="../../../assets/images/index/rcif/rcif-singer.png"
                  alt="Two singers on stage, singing a duet"
                />
              </div>
            </div>
          </div>

          {/* END SLIDER */}
          {/* content */}
          <div className={`${rcifHomeStyles.content}`}>
            <div className={rcifHomeStyles.rcifLogo}>
              <img
                src={
                  require("../../../assets/images/logos/rcif/rcif-horizontal-black.svg")
                    .default
                }
                alt="RCIF Logo"
              />
            </div>
            <h2>Our Mission</h2>
            <p>
              <b>
                We aim to provide impactful support to our local communities by
                awarding grants to build a clean, safe, and health community for
                our neighbors.
              </b>
            </p>
            <p>
              We are committed to improving our home in Nelsonville, Ohio and
              the surrounding communities by promoting philanthropy among our
              associates.
            </p>
            <Link to="/about-us/rcif/" className="CTAblack" title="RCIF Page">
              Learn More
            </Link>
          </div>
          {/* END content */}
        </div>
      </div>
      {/* renders on desktop, the purpose of having two is due to logical focus order differences */}
      <button
        onClick={handleAnimationToggle}
        className={`${rcifHomeStyles.sliderAnimationToggle} ${rcifHomeStyles.sliderAnimationToggleDesktop}`}
        style={{ left: buttonLeft }}
      >
        {isPlaying ? "Pause Slideshow" : "Play Slideshow"}
      </button>
    </div>
  )
}

export default RcifHome
