import React, { useState } from "react"

const PlayPauseButton = ({ direction, clickEvent, ariaLabel, isPlaying }) => {
  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  const pauseStyles = {
    display: `block`,
    width: `35px`,
    height: `35px`,
    padding: `6px`,
    justifyContent: `center`,
    alignItems: `center`,
    backgroundColor: isHovered ? `#9d5918` : `black`,
    cursor: `pointer`,
    transition: `all 0.2s ease-in-out`,
    transform:
      direction === "left"
        ? `rotate(180deg)`
        : direction === "right"
        ? `rotate(0deg)`
        : `rotate(0deg)`,
  }

  return (
    <>
      <button
        style={pauseStyles}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={clickEvent ? clickEvent : null}
        tabIndex="0"
        aria-label={ariaLabel ? ariaLabel : null}
      >
        {isPlaying === true ? (
          <img
            key="pause"
            src={
              require("../../../../../assets/icons/ui/pauseicon-white2.svg")
                .default
            }
            alt=""
          />
        ) : (
          <img
            key="play"
            src={
              require("../../../../../assets/icons/ui/playicon-white.svg")
                .default
            }
            alt=""
          />
        )}
      </button>
    </>
  )
}

export default PlayPauseButton
