import React from "react"
import HomeCarousel from "./HomeCarousel"

const HomeHero = () => {
  return (
    <>
      <HomeCarousel />
    </>
  )
}

export default HomeHero
