import React from "react"
import CareersHome from "./careers/CareersHome"
import ContactHome from "./contact/ContactHome"

const CareersAndContact = () => {
  return (
    <>
      <CareersHome />
      <ContactHome />
    </>
  )
}

export default CareersAndContact
