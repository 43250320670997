import axios from "axios"
import React, { useState } from "react"
import * as hubspotFormStyles from "./hubspotForm.module.scss"

const HubspotForm = () => {
  const [formState, setFormState] = useState({
    email: "",
  })
  const [errors, setErrors] = useState({
    email: "",
  })

  const handleChange = event => {
    setFormState({
      ...formState,
      [event.target.name]: event.target.value,
    })
  }

  const handleSubmit = async e => {
    e.preventDefault()

    const isValidEmail = value =>
      /\S+@\S+\.\S+/.test(value) ? "" : "Please provide a valid email address."

    const fieldsToValidate = [
      {
        field: "email",
        validate: isValidEmail,
      },
    ]

    // Perform validation
    let newErrors = {}
    fieldsToValidate.forEach(({ field, validate }) => {
      const value = formState[field]
      const validators = Array.isArray(validate) ? validate : [validate]
      validators.forEach(validator => {
        const error = validator(value)
        if (error) {
          newErrors[field] = error
        }
      })
    })

    if (Object.keys(newErrors).length > 0) {
      // Update error state with new errors
      setErrors(newErrors)

      // Find the first field with an error and focus on it
      const firstErrorField = document.querySelector(
        `#${Object.keys(newErrors)[0]}`
      )
      if (firstErrorField) {
        firstErrorField.focus()
      }
    } else {
      const hubspot_response = await submit_hubspot_form(formState.email)
      setFormState({ email: "" })
      setErrors({ email: "" })
    }
  }

  const submit_hubspot_form = async email => {
    const portalId = "4392020"
    const formId = "22730871-7ea6-4a9b-a31f-50b3beb74abb"
    const config = {
      // important!
      headers: {
        "Content-Type": "application/json",
      },
    }

    const response = await axios.post(
      `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`,
      {
        portalId,
        formId,
        fields: [
          {
            name: "email",
            value: email,
          },
        ],
      },
      config
    )
    return response
  }

  return (
    <div className={hubspotFormStyles.wrapper}>
      <form onSubmit={handleSubmit}>
        <label
          htmlFor="email"
          className={`required ${
            !formState.email ? "" : hubspotFormStyles.labelActive
          }`}
        >
          <small>Email Address</small>
        </label>

        <input
          id="email"
          name="email"
          type="email"
          aria-label="Email Address"
          aria-required="true"
          autoComplete="email"
          value={formState.email}
          onChange={handleChange}
          aria-describedby={errors.email ? "email-error" : null}
        />
        <button type="submit" aria-label="Submit">
          <img
            src={
              require("../../../../../assets/icons/ui/right-arrow-white.svg")
                .default
            }
            alt=""
          />
        </button>
      </form>
      {errors.email && (
        <div>
          <small id="email-error" className="text-danger">
            {errors.email} (i.e. email@domain.com)
          </small>
        </div>
      )}
    </div>
  )
}

export default HubspotForm
