import React from "react"
import * as contactHomeStyles from "./ContactHomeStyle.module.scss"
import mapIcon from "../../../../assets/images/index/careersandcontact/map-pin.svg"
import HubspotForm from "./hubspotForm/hubspotForm"

const ContactHome = () => {
  return (
    <section className={contactHomeStyles.contact}>
      {/* EMAIL */}
      <div className={contactHomeStyles.emailSignup}>
        <div className={contactHomeStyles.emailSignupContent}>
          <h2
            className="h2Alt"
            style={{ color: `black`, textAlign: `left`, marginBottom: `5px` }}
          >
            Sign me up!
          </h2>
          <p className={contactHomeStyles.beTheFirst}>
            <strong>
              Be the first to get updates on press releases, stocks, corporate
              events, and more.
            </strong>
            <br />
            <small style={{ fontSize: `0.8rem` }}>
              <span className="text-danger" style={{ fontSize: `1rem` }}>
                *
              </span>{" "}
              indicates required field
            </small>
          </p>
          <div style={{ display: `block` }}>
            <HubspotForm />
          </div>
          <p className={contactHomeStyles.disclaimer}>
            <small>
              By entering your email address you agree to receive corporate
              email communications from Rocky Brands, Inc. You may unsubscribe
              at any time.
            </small>
          </p>
        </div>
      </div>
      {/* CONTACT */}
      <div className={contactHomeStyles.location}>
        <div className={contactHomeStyles.map} role="region" aria-label="map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3080.481153344629!2d-82.23417778424734!3d39.45845757948803!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8847e1ebe9384371%3A0xf345b4531659ca00!2s39%20E%20Canal%20St%2C%20Nelsonville%2C%20OH%2045764!5e0!3m2!1sen!2sus!4v1660242287974!5m2!1sen!2sus"
            height="100%"
            width="100%"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            title="Google Map - Rocky Brands"
          ></iframe>
        </div>
        <div className={contactHomeStyles.info}>
          <div className={contactHomeStyles.mapIcon}>
            <img src={mapIcon} alt="" />
          </div>
          <div className={contactHomeStyles.contactInfo}>
            <p
              className={contactHomeStyles.corporate}
              role="heading"
              aria-level="2"
            >
              Rocky Brands, Inc. Corporate
            </p>
            <ul>
              <li>
                <p className={contactHomeStyles.address}>
                  39 E. Canal St. Nelsonville, OH 45764
                </p>
              </li>
              <li>
                <a
                  className={contactHomeStyles.phone}
                  href="tel:+18887195108"
                  title="Call Rocky Brands"
                >
                  888-719-5108
                </a>
              </li>
            </ul>
          </div>
          <a
            className={`CTAalt2 ${contactHomeStyles.contactCTA}`}
            href="/about-us/contact-us/"
            title="Contact Us"
          >
            Contact Us
          </a>
        </div>
      </div>
    </section>
  )
}

export default ContactHome
