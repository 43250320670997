import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as homeHeroSlideStyles from "./HomeHeroSlide.module.scss"

const HomeSlideThree = () => {
  return (
    <div
      className={`home-hero-slide ${homeHeroSlideStyles.heroImageWrapper}`}
      id="home-hero-campus"
    >
      <StaticImage
        src="../../../../assets/images/index/hero/carousel-image-3.jpg"
        className={homeHeroSlideStyles.heroImageMB}
        alt=""
        placeholder="tracedSVG"
      />
      <StaticImage
        src="../../../../assets/images/index/hero/carousel-image-3-TB.jpg"
        className={homeHeroSlideStyles.heroImageTB}
        alt=""
      />
      <StaticImage
        src="../../../../assets/images/index/hero/carousel-image-3-DT.jpg"
        className={homeHeroSlideStyles.heroImageDT}
        alt=""
        placeholder="tracedSVG"
      />
    </div>
  )
}

export default HomeSlideThree
