import React from "react"
import HomeCarouselWrapper from "./HomeCarouselWrapper"
import HomeSlideOne from "./slides/HomeSlideOne"
import HomeSlideThree from "./slides/HomeSlideThree"
import HomeSlideTwo from "./slides/HomeSlideTwo"

const HomeCarousel = () => {
  return (
    <div>
      <HomeCarouselWrapper>
        <HomeSlideOne />
        <HomeSlideTwo />
        <HomeSlideThree />
      </HomeCarouselWrapper>
    </div>
  )
}
export default HomeCarousel
